<template>
<!-- 提交订单（邮寄） -->
  <div class="content-page">
    <b-card>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active">
            <router-link to="/webstore">Webstore</router-link>
          </li>
          <li class="breadcrumb-item" aria-current="page"><router-link to="/WebstoreCart">Cart</router-link></li>
          <li class="breadcrumb-item" aria-current="page">Submit Order</li>
        </ol>
      </nav>
    </b-card>
    <b-card class="mt-3 p-3">
      <div class="row">
        <div class="col text-left">Shipping address</div>
        <div class="col text-right">
          <a href="#">Manage shipping address</a>
        </div>
      </div>
      <div class="border"></div>
      <div class="row default mt-3">
        <div class="col-12 col-md-6">
          <input type="radio" checked /> 238-240 Lockhart Road, Wan Chai, Hong
          Kong(Consignee:Lemowen)
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col">
              <span>18162424313</span
              ><strong class="ml-2"> Default address</strong>
            </div>
            <div class="col text-right">
              <a href="#">Modify address</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <input type="radio" /> 238-240 Lockhart Road, Wan Chai, Hong
          Kong(Consignee:Lemowen)
        </div>
        <div class="col-12 col-md-6">
          <span>18162424313 </span>
          <a class="ml-2" href="#"> Set as default address</a>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <input type="radio" /> 238-240 Lockhart Road, Wan Chai, Hong
          Kong(Consignee:Lemowen)
        </div>
        <div class="col-12 col-md-6">
          <span>18162424313 </span>
        </div>
      </div>
    </b-card>
    <b-card class="mt-3 p-3">
      <h5><i class="ri-store-2-line"></i> Meiji Chocolate Cup</h5>
      <!-- 商品详情 -->
      <div class="row mt-3">
        <div class="col-2 text-center v-center">
          <img class="w-75" src="../../../assets/images/user/02.jpg" alt="" />
        </div>
        <div class="col-4 text-left v-center">
          <p class="title mb-sm-5">Meiji Chocolate Cup</p>
          <p>Size : S</p>
        </div>
        <div class="col-3 text-center v-center">
          <p class="m-0">$20.00*1</p>
        </div>
        <div class="col-3 text-right v-center">
          <p class="m-0">$75.00</p>
        </div>
      </div>

      <div class="border"></div>
      <div class="row">
        <div class="col-2 text-center v-center">
          <img class="w-75" src="../../../assets/images/user/02.jpg" alt="" />
        </div>
        <div class="col-4 text-left v-center">
          <p class="title mb-sm-5">Meiji Chocolate Cup</p>
          <p>Size : S</p>
        </div>
        <div class="col-3 text-center v-center">
          <p class="m-0">$20.00*1</p>
        </div>
        <div class="col-3 text-right v-center">
          <p class="m-0">$75.00</p>
        </div>
      </div>
      <div class="border"></div>
      <div class="row">
        <div class="col-12 col-md-6">
          <input
            class="int-area"
            type="text"
            placeholder="Leave a message to the seller"
          />
        </div>
        <div class="col-12 col-md-6 text-md-right">
          <p class="price">
            Freight:<span style="color: #333"> Free Shipping</span>
          </p>
          <p class="price">Total:<strong style="color: #333"> $375.50</strong></p>
        </div>
      </div>
    </b-card>
    <b-card class="mt-3 p-3">
      <h5><i class="ri-store-2-line"></i> Meiji Chocolate Cup</h5>
     
      <div class="row mt-3">
        <div class="col-2 text-center v-center">
          <img class="w-75" src="../../../assets/images/user/02.jpg" alt="" />
        </div>
        <div class="col-4 text-left v-center">
          <p class="title mb-sm-5">Meiji Chocolate Cup</p>
          <p>Size : S</p>
        </div>
        <div class="col-3 text-center v-center">
          <p class="m-0">$20.00*1</p>
        </div>
        <div class="col-3 text-right v-center">
          <p class="m-0">$75.00</p>
        </div>
      </div>

      <div class="border"></div>
      <div class="row">
        <div class="col-2 text-center v-center">
          <img class="w-75" src="../../../assets/images/user/02.jpg" alt="" />
        </div>
        <div class="col-4 text-left v-center">
          <p class="title mb-sm-5">Meiji Chocolate Cup</p>
          <p>Size : S</p>
        </div>
        <div class="col-3 text-center v-center">
          <p class="m-0">$20.00*1</p>
        </div>
        <div class="col-3 text-right v-center">
          <p class="m-0">$75.00</p>
        </div>
      </div>
      <div class="border"></div>
      <div class="row">
        <div class="col-12 col-md-6">
          <input
            class="int-area"
            type="text"
            placeholder="Leave a message to the seller"
          />
        </div>
        <div class="col-12 col-md-6 text-md-right">
          <p class="price">Freight:<span style="color: #333"> $10.00</span></p>
          <p class="price">Total:<strong style="color: #333"> $375.50</strong></p>
        </div>
      </div>
    </b-card>
    <b-card class="mt-3 mb-3 p-3">
      <div class="row">
        <div class="col v-center">
          <p class="m-0 price">Accounts payable: <strong style="color:#333">$608.50</strong></p>
        </div>
        <div class="col text-right">
          <button class="btn">Place order</button>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
  color: #333;
  .breadcrumb {
    background: #fff;
    margin: 0;
  }
}
.border {
  width: 100%;
  height: 1px;
  background: #f1f1f1;
  margin: 20px 0;
}
.default {
  background: #f5fbff;
  border: 1px solid #50b5ff;
  padding: 8px 0;
}
.v-center {
  margin: auto 0;
}
.int-area {
  width: 80%;
  height: 80px;
  padding: 0 0 40px 10px;
  background: #f8f8f8;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
}
.price {
  font-size: 14px;
  font-family: Poppins Medium, Poppins Medium-Medium;
  font-weight: 500;
  color: #999;
}
.btn {
  width: 120px;
  height: 45px;
  color: #ffffff;
  background: #50b5ff;
  border-radius: 5px;
}
</style>